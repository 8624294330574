import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {findTransactions} from "../../actions/Orders";
import {profile, update} from "../../actions/Auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserTransactionsView from "./UserTransactionsView";
import Icons from "../../models/Icons";
import StateTool from "../../tools/StateTool";
import {FormProvider, useForm} from "react-hook-form";
import {birthdayField, firstNameField, lastNameField, phoneField} from "./UserProfileFields";
import {Input} from "../form/Input";
import {Event} from "../../models/Analytics";
import useAnalytics from "../../hooks/Analytics";
import Message from "../form/Message";
import ErrorTool from "../../tools/ErrorTool";

const UserProfileView = () => {

    const NOT_AVAILABLE = "n/a";
    const [state, setState] = useState({
        edit: false,
        firstName: null,
        lastName: null,
        birthday: null,
        phone: null,
        error: null
    });
    const tool = new StateTool(state, setState);
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const user = useSelector(state => state.auth.profile);
    const transactions = useSelector(state => state.orders.transactions);

    useEffect(() => {
        if (!transactions)
            dispatch(findTransactions({
                page: 1,
                size: 28,
                filter: [],
                sort: [{property: 'timeCreated', direction: -1}]
            }));
    }, [transactions])

    useEffect(() => {
        if (!user && !state.id)
            dispatch(profile())
        else unwrapState();
    }, [user]);

    const unwrapState = () => {
        if (!user) return;
        const [firstName, lastName] = user?.name?.split(" ");
        const [birthday] = (user?.birthday || "").split(" ");
        tool.apply({
            ...user,
            firstName,
            lastName,
            birthday
        });
    }

    const onSubmit = form.handleSubmit(data => {
        tool.apply({
            edit: false,
            error: null
        });
        dispatch(update({
            firstName: data?.firstName,
            lastName: data?.lastName,
            name: data?.name,
            birthday: data?.birthday + " 00:00:00",
            phone: parseInt(data?.phone)
        })).then(_ => {
            analytics.event(Event.create(Event.UPDATE_USER, {
                user_id: user?.id
            }))
        }).catch(error => {
            tool.set("error", error.response?.data);
            analytics.event(Event.create(Event.EXCEPTION, {
                ...error?.response?.data,
                description: error?.response?.data?.message,
                fatal: false
            }));
        })
    });

    return (state.id ? <section style={{marginTop: 100}}>
        <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100" style={{minHeight: 540}}>
                <div className="col-md-5 col-lg-6 col-xl-3 mb-5">
                    <div className="card">
                        <div className="card-body box-profile">
                            <div style={{position: 'absolute', right: 15}}>
                                <FontAwesomeIcon icon={Icons.USER_PROFILE_EDIT}
                                                 style={{width: 20, height: 20, color: 'grey'}}
                                                 onClick={_ => tool.set("edit", !state.edit)}/>
                            </div>
                            <div className="profile-user-img img-responsive img-circle"
                                 style={{width: 100, height: 100, overflow: 'hidden', color: 'grey'}}>
                                <FontAwesomeIcon icon={Icons.USER_PROFILE_ICON}
                                                 style={{width: '100%', height: '100%'}}/>
                            </div>

                            <h3 className="profile-username text-center">{state.firstName + ' ' + state.lastName}</h3>
                            <p className="text-muted text-center text-bold">{state.email}</p>

                            {!state.edit && <div>
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>Username</b> <span className="pull-right">{state?.username}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Phone</b> <span
                                        className="pull-right">{state?.phone ? '+' + state?.phone : NOT_AVAILABLE}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Birthday</b> <span
                                        className="pull-right">{state?.birthday ? state?.birthday : NOT_AVAILABLE}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Status</b> <span className="pull-right">{state?.status}</span>
                                    </li>
                                    <li style={{height: 20, listStyle: 'none'}}>&nbsp;</li>
                                </ul>
                            </div>}

                            {state.edit && <FormProvider {...form}>
                                <form noValidate onSubmit={e => e.preventDefault()}>
                                    <div className="row">
                                        <div className="form-outline mb-4 col col-md-6">
                                            <Input {...firstNameField} value={state.firstName}
                                                   onChange={e => tool.set("firstName", e.target.value)}/>
                                        </div>
                                        <div className="form-outline mb-4 col col-md-6">
                                            <Input {...lastNameField} value={state.lastName}
                                                   onChange={e => tool.set("lastName", e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="form-outline mb-4 col col-md-12">
                                        <Input {...birthdayField} value={state.birthday}
                                               onChange={e => tool.set("birthday", e.target.value)}/>
                                    </div>
                                    <div className="form-outline mb-4 col col-md-12">
                                        <Input {...phoneField} value={state.phone}
                                               onChange={e => tool.set("phone", e.target.value)}/>
                                    </div>
                                </form>
                            </FormProvider>}

                            {!state.edit &&
                            <button onClick={_ => tool.set("edit", true)} className="btn btn-primary w-100">
                                <b>Edit</b>
                            </button>}
                            {state.edit && <button onClick={_ => onSubmit()} className="btn btn-primary w-100">
                                <b>Save</b>
                            </button>}

                            {state.error && <Message level="warning" title="User Profile Error" className="mt-3 mb-0"
                                         text={new ErrorTool(state.error).getMessage()} />}

                            {transactions?.data?.length > 0 && <>
                                <ul className="list-group list-group-unbordered">
                                    <li style={{height: 20, listStyle: 'none'}}>&nbsp;</li>
                                    <li className="list-group-item">
                                        <b>Orders #</b> <span
                                        className="pull-right">{transactions?.data?.length ?? NOT_AVAILABLE}</span>
                                    </li>
                                </ul>
                                <UserTransactionsView transactions={transactions}/>
                            </>}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </section> : <></>)
}

export default UserProfileView;

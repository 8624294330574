import AuthService from "../../services/AuthService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsersSlash} from "@fortawesome/free-solid-svg-icons/faUsersSlash";
import {Link} from "react-router-dom";
import {hideModal, showModal} from "../../actions/Modal";
import Modal from "../../models/Modal";
import {useDispatch} from "react-redux";
import Icons from "../../models/Icons";
import {Routes} from "../../models/Route";

const GuestUserGuard = (props) => {

    const dispatch = useDispatch();

    const onClick = (event) => {
        if (AuthService.isGuest) {
            event.stopPropagation();
            event.preventDefault();
            displayModal();
        } else props.onClick(event);
    }

    const displayModal = () => {
        const content = (<>
            <FontAwesomeIcon icon={Icons.GUEST_DIALOG} style={{width: 150, height: 150}} />
            <div className="text-primary" style={{lineHeight: '18px'}}>
                Please Sign In or Sign Up in order to continue with the shopping experience
            </div>
        </>);
        const controls = <>
            <Link to={Routes.USER_SIGN_IN} className="w-100">
                <button className="btn btn-dark py-2 w-100" onClick={removeModal}>
                    <FontAwesomeIcon icon={Icons.USER_SIGN_IN} /> &nbsp; Sign In
                </button>
            </Link>
            <Link to={Routes.USER_SIGN_UP} className="w-100">
                <button className="btn btn-outline-dark py-2 w-100" onClick={removeModal}>
                    <FontAwesomeIcon icon={Icons.USER_SIGN_UP} /> &nbsp; Sign Up
                </button>
            </Link>
        </>
        dispatch(showModal(new Modal("Not Signed In", content, controls)))
    }

    const removeModal = () => {
        dispatch(hideModal());
    }

    return <div className="w-100" onClick={e => onClick(e)}>{props.children}</div>
}
export default GuestUserGuard;

import AuthService from "../services/AuthService";
import axios from "./Axios";
import store from "../store/Store";
import {authenticate, logout, refresh} from "../actions/Auth";
import DelayTool from "../tools/DelayTool";

let $token;

export const interceptor = () => {

    const ignored = ["auth/token", "auth/user/register", "auth/user/password", "auth/code"]
    const {dispatch} = store;

    // request
    axios.interceptors.request.use(request => {
        // console.info("intercept", request.url);
        if (request?.authorization === false) return;
        const auth = AuthService.authentication;
        const isLoggedIn = !!auth?.token;
        request.headers['Content-Type'] = 'application/json';
        if (isLoggedIn) {
            request.headers.Authorization = `Bearer ${auth.token}`;
        }
        return request;
    }, error => {
        if (error.response)
            console.error('Server Error:', error.response.status);
        else if (error.request)
            console.error('Network Error:', error.request);
        else
            console.error('Error:', error.message);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        return response;
    }, async error => {
        const request = error.config
        if (error?.response?.status === 401 && ignored.some(uri => request.url.endsWith(uri))) {
            // router.push("/login")
            return Promise.reject(error)
        }
        if (error?.response?.status === 401 && !request._retry) {
            request._retry = true;
            const {username, password, application, tenant} = AuthService.USER_DEFAULT;
            if (AuthService.authentication && !AuthService.isGuest) {
                try {
                    if (!$token) $token = dispatch(refresh());
                } catch (e) {
                    dispatch(logout());
                    $token = dispatch(authenticate(username, password, application, tenant));
                }
                await $token;
                console.info("refresh.ok", $token);
                await DelayTool.delay(1000);
                $token = null;
                // if (res?.status === 200)
                return axios.request(request);
            } else {
                try {
                    if (!$token) $token = dispatch(authenticate(username, password, application, tenant));
                } catch (e) {
                    dispatch(logout());
                }
                await $token;
                console.info("auth.ok", $token);
                await DelayTool.delay(1000);
                $token = null;
                // if (res?.status === 200)
                return axios.request(request);
            }
        }
        return Promise.reject(error);
    });
}

export default class ProductTool {

    #product;

    constructor(product) {
        this.#product = product;
    }

    price() {
        return (this.#product.price ? this.#product.price : 0);
    }

    taxPrice() {
        const offPrice = this.price() - this.discountPrice();
        return (this.#product?.tax ? (this.#product?.tax * offPrice) / 100 : 0);
    }

    discountPrice() {
        return (this.#product?.discount ? (this.#product?.discount * this.#product?.price) / 100 : 0);
    }

    totalPrice() {
        return this.taxPrice() + (this.#product.shipping || 0);
    }

    shipping() {
        return this.#product.shipping || 0;
    }

}

import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ProductCartCard from "./ProductCartCard";
import StateTool from "../../tools/StateTool";
import OrderSummary from "./OrderSummary";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import {Currencies} from "../../models/Currency";
import ProductTool from "../../tools/ProductTool";

const ShoppingCartView = () => {

    const orders = useSelector(state => state.orders);
    const analytics = useAnalytics();
    const [state, setState] = useState({show: false});
    const tool = new StateTool(state, setState);

    useEffect(() => {
        if (!orders.cart) return;
        setTimeout(() => {
            tool.set("show", true);
        }, 10)
        analytics.event(Event.create(Event.VIEW_CART, {
            currency: Currencies.EUR.code,
            value: orders.cart.amount,
            items: orders.cart.products.map((p, i) => analytics.product(p.product, i, p.quantity))
        }))
        return () => () => {
            tool.set("show", false);
        }
    }, [orders.cart])

    const onRemove = (product) => {
        const tool = new ProductTool(product.product);
        analytics.reset();
        analytics.event(Event.create(Event.REMOVE_FROM_CART, {
            currency: Currencies.EUR.code,
            value: tool.totalPrice() * product.quantity,
            items: [analytics.product(product.product, orders.cart.products.indexOf(product), product.quantity)]
        }))
    }

    const onUpdate = (product) => {
        const tool = new ProductTool(product.product);
        analytics.reset();
        analytics.event(Event.create(Event.ADD_TO_CART, {
            currency: Currencies.EUR.code,
            value: tool.totalPrice() * product.quantity,
            items: [analytics.product(product.product, orders.cart.products.indexOf(product), product.quantity)]
        }))
    }

    return (
        <div className="container mt-5 py-4 px-xl-5">
            <ScrollToTopOnMount/>
            {state.show && <>
                <Breadcrumbs data={["Shopping Cart"]}/>
                <div className="row">
                    <div className="col col-md-8">
                        <div className="row m-0 p-0">
                            {orders.cart?.products.map((product, i) =>
                                <ProductCartCard key={product.product.sku + i} product={product}
                                                 onRemove={onRemove} onUpdate={onUpdate}/>
                            )}
                            {orders.cart?.products?.length === 0 && <h5>Your shopping cart is empty</h5>}
                        </div>
                    </div>
                    {orders.cart?.products?.length > 0 && <OrderSummary cart={orders.cart} showButton={true}/>}
                </div>
            </>}
        </div>
    );
}
export default ShoppingCartView;

import {Link} from "react-router-dom";
import {Routes} from "../../models/Route";
import PropTypes from "prop-types";
import Icons from "../../models/Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Currencies} from "../../models/Currency";

const OrderSummary = ({cart, showButton}) => {

    return <div className="col-lg-4">
        <div className="card mb-4">
            <div className="card-header py-3 bg-white">
                <h5 className="mb-0">Order Summary</h5>
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Products
                        <span>{Currencies.EUR.symbol + ' ' + cart?.price?.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Discount
                        <span>{Currencies.EUR.symbol + ' ' + cart?.discount?.toFixed(2) || 0}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Shipping
                        <span>{Currencies.EUR.symbol + ' ' + cart?.ship?.toFixed(2) || 0}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        Tax
                        <span>{Currencies.EUR.symbol + ' ' + cart?.tax?.toFixed(2) || 0}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                        <div>
                            <strong>Total amount</strong>
                            <strong>
                                <p className="mb-0">(including VAT)</p>
                            </strong>
                        </div>
                        <span><strong>{Currencies.EUR.symbol + ' ' + cart?.amount?.toFixed(2)}</strong></span>
                    </li>
                    <span className={showButton ? "mb-3" : ""} />
                </ul>

                {showButton && <Link to={Routes.CHECKOUT}>
                    <button type="button" className="btn btn-primary btn-lg btn-block w-100">
                        <FontAwesomeIcon icon={Icons.CHECKOUT} /> &nbsp; Checkout
                    </button>
                </Link>}
            </div>
        </div>
    </div>
}

OrderSummary.propTypes = {
    cart: PropTypes.any,
    showButton: PropTypes.bool.isRequired
}
OrderSummary.defaultProps = {
    showButton: false
}

export default OrderSummary;

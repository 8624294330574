import {Link} from "react-router-dom";
import Image from "../image/Image";
import ProductTool from "../../tools/ProductTool";
import {Routes} from "../../models/Route";
import PropTypes from "prop-types";

const ProductCardH = ({product, onClick}) => {

    const tool = new ProductTool(product);

    let percentOff;
    let offPrice = <b>{product?.price} €</b>;

    if (product?.discount && product?.discount > 0) {
        percentOff = (
            <div className="badge bg-dim py-2 text-white position-absolute" style={{top: "0.5rem", left: "0.5rem"}}>
                {product?.discount}% OFF
            </div>
        );
        offPrice = (
            <>
                <del>{product?.price} €</del>
                &nbsp;&nbsp;
                <b>{product?.price - tool.discountPrice()} €</b>
            </>
        );
    }

    const uri = Routes.PRODUCT.replace(":id", product.id);

    return (
        <div className="col">
            <div className="card shadow-sm">
                <div className="row g-0">
                    <div className="col-4">
                        <Link to={uri} onClick={e => onClick(product)}
                              style={{height: 140, background: "#e9e9e9", display: 'block'}}>
                            {percentOff}
                            <Image className="rounded-start bg-white cover w-100" alt="" id={product?.images[0]}
                                   height={140}/>
                        </Link>
                    </div>
                    <div className="col-8">
                        <div className="card-body h-100">
                            <div className="d-flex flex-column h-100">
                                <h5 className="card-title text-dark text-truncate mb-1">
                                    {product?.name}
                                </h5>
                                <span className="card-text text-muted mb-2 flex-shrink-0">{offPrice}</span>
                                <div className="mt-auto d-flex">
                                    <Link to={uri} style={{marginLeft: 'auto'}} onClick={e => onClick(product)}>
                                        <button className="btn btn-outline-dark ms-auto">
                                            Details
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCardH;

ProductCardH.propTypes = {
    product: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired
}
ProductCardH.defaultProps = {
    onClick: () => {
    }
}

import StateTool from "../../tools/StateTool";
import {useEffect, useState} from "react";

const ProductPagination = ({products, state, setState}) => {

    const tool = new StateTool(state, setState);
    const [pages, setPages] = useState([]);

    const onClick = (i) => () => {
        if (state.page !== i)
            tool.set("page", i);
    }

    const arrange = () => {
        const pages = [];
        const count = 6;
        let max = Math.min(products?.pages, (state.page + count / 2)),
            min = Math.max(max - count + 1, 1);
        max += -Math.min(max - count, 0);
        min += -Math.max(count - max, 0);
        for (let i = min; i <= max; i++) {
            if (i <= products?.pages) pages.push(i);
        }

        setPages(pages);
    }

    useEffect(() => {
        arrange();
    }, [products])

    return (
        <nav aria-label="Product search pagination" className="ms-auto">
            <ul className="pagination my-0">
                <li className="page-item">
                    <a className="page-link" onClick={_ => state.page > 1 ? tool.set("page", state.page - 1) : null}>
                        &lt;
                    </a>
                </li>
                {pages.map((page, i) =>
                    <li key={i + 1} className={`page-item ${page - 1 === products?.page ? 'active' : ''}`}
                        onClick={onClick(page)}>
                        <a className="page-link">{page}</a>
                    </li>
                )}
                <li className="page-item">
                    <a className="page-link"
                       onClick={_ => state.page < products?.pages ? tool.set("page", state.page + 1) : null}>
                        &gt;
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default ProductPagination;

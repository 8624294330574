import useImageLoader from "../../hooks/ImageLoader";

const BannerIndicator = (props) => {
    return (
        <button
            type="button"
            data-bs-target="#bannerIndicators"
            data-bs-slide-to={props.index}
            className={props.active ? "active" : ""}
            aria-current={props.active}/>
    );
}

const BannerImage = (props) => {
    return (
        <div className={"carousel-item " + (props.active ? "active" : "")} data-bs-interval="5000">
            <div className="ratio" style={{"--bs-aspect-ratio": "50%", maxHeight: "460px"}}>
                <img className="d-block w-100 h-100 bg-grey cover" alt="" src={props.image}/>
            </div>
            <div className="carousel-caption d-none d-lg-block">
                <p>{props.text ?? ''}</p>
            </div>
        </div>
    );
}

const Banner = () => {

    const images = {
        [process.env.PUBLIC_URL + "/images/banner_09.png"]: 'Road Signalization - The Art of Motion',
        [process.env.PUBLIC_URL + "/images/banner_07.png"]: 'Attested & Certified Road Signalization Products',
        [process.env.PUBLIC_URL + "/images/banner_12.png"]: 'Road Signalization Empowers Road Safety',
        [process.env.PUBLIC_URL + "/images/banner_06.png"]: 'Largest Manufacturer of Road Signalization in North Macedonia'
    }
    useImageLoader(Object.keys(images));

    return (
        <div id="bannerIndicators"
             className="carousel slide"
             data-bs-ride="carousel"
             style={{marginTop: "56px"}}>
            <div className="carousel-indicators">
                <BannerIndicator index="0" active={true}/>
                <BannerIndicator index="1"/>
                <BannerIndicator index="2"/>
                <BannerIndicator index="3"/>
            </div>
            <div className="carousel-inner">
                {Object.keys(images).map((image, idx) =>
                    <BannerImage image={image} active={idx === 0} key={idx} text={images[image]}/>
                )}
            </div>
        </div>
    );
}

export default Banner;

import Image from "../image/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Ratings from "react-ratings-declarative";
import {useCallback, useEffect, useState} from "react";
import StateTool from "../../tools/StateTool";
import {useDispatch, useSelector} from "react-redux";
import {createCartItem, updateCartItem} from "../../actions/Orders";
import Modal from "../../models/Modal";
import {showModal} from "../../actions/Modal";
import {Link, useHistory} from "react-router-dom";
import GuestUserGuard from "../auth/GuestUserGuard";
import {Routes} from "../../models/Route";
import Icons from "../../models/Icons";
import Message from "../form/Message";
import ProductTool from "../../tools/ProductTool";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import {Currencies} from "../../models/Currency";
import StringTool from "../../tools/StringTool";

const iconPath =
    "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

const ProductDetail = ({product, state, setState}) => {

    const dividerStyle = {height: 0, margin: 0};
    const tool = new StateTool(state, setState);
    const dispatch = useDispatch();
    const history = useHistory();
    const [image, setImage] = useState();
    const [imageHeight, setImageHeight] = useState(100);
    const [error, setError] = useState();
    const analytics = useAnalytics();
    const cart = useSelector(state => state.orders.cart);
    const getProduct = () => state.product ?? product;
    let percentOfF = <></>;
    let offPrice = `${getProduct()?.price} €`;

    if (getProduct().discount > 0) {
        percentOfF = (
            <div className="badge bg-dim py-2 text-white position-absolute" style={{top: "0.5rem", left: "0.5rem", zIndex: 1}}>
                {getProduct().discount}% OFF
            </div>
        )
        offPrice = (
            <>
                <del>{getProduct()?.price} €</del>&nbsp;&nbsp;
                {getProduct()?.price - new ProductTool(getProduct()).discountPrice()} €
            </>
        );
    }

    const showImage = (image) => {
        setImage(undefined);
        analytics.event(Event.create(Event.IMAGE_VIEW, {image: image}))
        setTimeout(() => {
            setImage(image);
        }, 30)
    }

    const addToCart = (redirectToCart) => () => {
        if (!state.product) {
            setError("Please select product variation");
            return;
        }
        if (!cart) {
            setError("Please try again later, loading shopping cart");
            return;
        }
        setError(null);
        const match = cart.products.find(p => p.product.id === state.product.id);
        if (match) match.quantity += state.quantity;
        const item = {orderId: cart.id, product: state.product, quantity: state.quantity, create: !!!match};
        dispatch(match ? updateCartItem(match) : createCartItem(item)).then(res => {
            const tool = new ProductTool(res.data.product);
            analytics.event(Event.create(Event.ADD_TO_CART, {
                currency: Currencies.EUR.code,
                value: tool.price(),
                items: [analytics.product(res.data.product, 0, res.data.quantity)]
            }))
            if (redirectToCart) history.push(Routes.CART)
            else displayModal();
        });
    }

    const displayModal = () => {
        const content = <>
            <div className="rounded-circle shadow-4-strong">
                <FontAwesomeIcon icon={Icons.CART_ADD_DIALOG} style={{width: 150, height: 150, marginLeft: -10}}/>
            </div>
            <div className="text-primary" style={{lineHeight: '18px'}}>
                {state.quantity} item(s) of <b>{state.product.name}</b> have been added to your Shopping Cart
            </div>
        </>;
        const controls = <>
            <Link to={Routes.CART} className="w-100">
                <button className="btn btn-dark py-2 w-100">
                    <FontAwesomeIcon icon={Icons.SHOPPING_CART_VIEW} /> &nbsp; View Cart
                </button>
            </Link>
            <Link to={Routes.CHECKOUT} className="w-100">
                <button className="btn btn-outline-dark py-2 w-100">
                    <FontAwesomeIcon icon={Icons.CHECKOUT} /> &nbsp; Checkout
                </button>
            </Link>
        </>
        dispatch(showModal(new Modal("Item In Cart", content, controls)))
    }

    const imageRef = useCallback(node => {
        if (node == null) return;
        setImageHeight(node.getBoundingClientRect().height);
    }, []);

    const updateQuantity = (event) => {
        if (StringTool.isBlank(event.target.value)) tool.set("quantity", 1);
    }

    const updateRating = () => {
        const ratings = [1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5];
        product.rating = ratings[Math.floor(Math.random() * ratings.length)];
    }

    const handleResize = () => {
        showImage(product.images[0]);
    }

    useEffect(() => {
        if (!product) return;
        if (product?.products?.length === 0)
            tool.set("product", product);
        else tool.set("product", null);
        updateRating();
        showImage(product.images[0]);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [product])

    return <div className="row mb-0">

        <div className="d-none d-lg-block col-lg-1">
            <div className="image-vertical-scroller">
                <div className="d-flex flex-column">
                    {product?.images.map((img, i) =>
                        <Image key={i} className="border rounded ratio ratio-1x1 bg-white" alt="" id={img} onClick={showImage}/>
                    )}
                </div>
            </div>
        </div>

        <div className="col-lg-6">
            <div className="row">
                <div className="col-12 mb-4" style={{position: 'relative', height: imageHeight}}>
                    {percentOfF}
                    {image ? <Image className="border rounded ratio ratio-1x1 bg-white" alt="" id={image} ref={imageRef}/> : <></>}
                </div>
            </div>
            {product?.images?.length > 1 && <div className="row mb-3 d-lg-none d-sm-block">
                <div className="col-12">
                    <div className="d-flex flex-nowrap" style={{ overflow: "hidden", overflowX: 'scroll'}}>
                        {product?.images.map((img, i) =>
                            <Image key={i} className="cover rounded ratio ratio-1x1 bg-white" alt="" id={img} onClick={showImage}
                                   width={100} height={100}/>
                        )}
                    </div>
                </div>
            </div>}
        </div>

        <div className="col-lg-5">
            <div className="d-flex flex-column h-100">
                <h2 className="mb-1">{product?.name}</h2>
                <h4 className="text-muted mb-4">{offPrice}</h4>

                <div className="row g-3 mb-4">
                    <div className="col">
                        <GuestUserGuard onClick={addToCart(false)}>
                            <button className="btn btn-outline-dark py-2 w-100">
                                <FontAwesomeIcon icon={Icons.CART_ADD}/> &nbsp; To Cart
                            </button>
                        </GuestUserGuard>
                    </div>
                    <div className="col">
                        <GuestUserGuard onClick={addToCart(true)}>
                            <button className="btn btn-dark py-2 w-100">
                                <FontAwesomeIcon icon={Icons.BUY_NOW}/> &nbsp; Buy Now
                            </button>
                        </GuestUserGuard>
                    </div>
                </div>
                <div className="row g-3 mb-4">
                    <div className="col-sm-3">
                        <input type="number" min="1" max="3333" placeholder="Quantity" onBlur={e => updateQuantity(e)}
                               className="form-control" value={state.quantity}
                               style={{width: '100%', fontWeight: 'bold', border: '1px solid black'}}
                               onChange={e => tool.set("quantity", StringTool.isNotBlank(e.target.value) ? parseInt(e.target.value) : null)}/>
                    </div>
                    <div className="col-sm-9">
                        <select className="form-select" style={{border: '1px solid black'}}
                                disabled={product?.products?.length === 0}
                                onChange={event => tool.set("product", product?.products?.find(p => p.id === event.target.value))}>
                            <option value>Select Variation</option>
                            {product?.products?.map((p, i) =>
                                <option key={i} value={p.id}>{p.name}</option>
                            )}
                        </select>
                    </div>
                </div>
                {error && <Message level="danger" text={error} title="Shopping cart Issue" />}
                <h5 className="mb-0">Details</h5>
                <hr/>
                <dl className="row">
                    <dt className="col col-sm-4">Code</dt>
                    <dd className="col col-sm-8 mb-3">{product?.sku}</dd>
                    <dd className="col-sm-12" style={dividerStyle}/>
                    <dt className="col col-sm-4">Category</dt>
                    <dd className="col col-sm-8 mb-3">{product?.category.name}</dd>
                    <dd className="col-sm-12" style={dividerStyle}/>
                    <dt className="col col-sm-4">Manufacturer</dt>
                    <dd className="col col-sm-8 mb-3">Tehnodidakta</dd>
                    {product?.color && <>
                        <dd className="col-sm-12" style={dividerStyle}/>
                        <dt className="col col-sm-4">Color</dt>
                        <dd className="col col-sm-8 mb-3">{product?.color}</dd>
                    </>}
                    {product?.weight > 0 && <>
                        <dd className="col-sm-12" style={dividerStyle}/>
                        <dt className="col col-sm-4">Weight</dt>
                        <dd className="col col-sm-8 mb-3">{product?.weight}</dd>
                    </>}
                    <dd className="col-sm-12" style={dividerStyle}/>
                    <dt className="col col-sm-4">Status</dt>
                    <dd className="col col-sm-8 mb-3">{product?.status}</dd>
                    <dd className="col-sm-12" style={dividerStyle}/>
                    <dt className="col col-sm-4">Rating</dt>
                    <dd className="col col-sm-8 mb-3">
                        <Ratings
                            rating={product?.rating || 0}
                            widgetRatedColors="rgb(253, 204, 13)"
                            changeRating={e => product.rating = e}
                            widgetSpacings="2px">
                            {Array.from({length: 5}, (_, i) => {
                                return (
                                    <Ratings.Widget
                                        key={i}
                                        widgetDimension="20px"
                                        svgIconViewBox="0 0 19 20"
                                        svgIconPath={iconPath}
                                        widgetHoverColor="rgb(253, 204, 13)"
                                    />
                                );
                            })}
                        </Ratings>
                    </dd>
                </dl>

                <h5 className="mb-0">Description</h5>
                <hr/>
                <p className="lead flex-shrink-0">
                    <small>{product?.description}</small>
                </p>
            </div>
        </div>
    </div>
}

export default ProductDetail;

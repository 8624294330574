export class Authentication {
    id;
    token;
    refresh;
    application;
    username;
    tenant;
    authorities;
    ip;
    timestamp;
}

export class Grant {
    static Identity = "identity";
    static Credential = "credential";
    static Code = "code";
    static Password = "password";
    static Refresh = "refresh";
}

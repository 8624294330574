export class EventContext {

    static PAGE_VIEW = "page_view";
    static SEARCH = "search";
    static IMAGE_VIEW = "image_view";
    static VIEW_ITEM_LIST = "view_item_list";
    static SELECT_ITEM = "select_item";
    static VIEW_ITEM = "view_item";
    static VIEW_CART = "view_cart";
    static ADD_TO_CART = "add_to_cart";
    static REMOVE_FROM_CART = "remove_from_cart";
    static BEGIN_CHECKOUT = "begin_checkout";
    static ADD_SHIPPING_INFO = "add_shipping_info";
    static ADD_PAYMENT_INFO = "add_payment_info";
    static PURCHASE = "purchase";
    static EXCEPTION = "exception";
    static SIGN_IN = "sign_in";
    static SIGN_UP = "sign_up";
    static SIGN_OUT = "sign_out";
    static PASS_CODE = "pass_code";
    static PASS_RESET = "pass_reset";
    static UPDATE_USER = "update_user";
    static SEND_MESSAGE = "send_message";

    static create(event, data) {
        return new Event(event, data);
    }

}

export class Event extends EventContext {

    event;

    constructor(event, data) {
        super();
        this.event = event;
        Object.assign(this, data);
    }
}


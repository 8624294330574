import {SEARCH_CATEGORIES, SEARCH_CATEGORIES_ERROR, SEARCH_CATEGORIES_SUCCESS} from "../actions/Types";
import State from "../models/State";

const initialState = new State();

const CategoryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_CATEGORIES:
            return {...state, loading: true};
        case SEARCH_CATEGORIES_SUCCESS:
            return {...state, loading: false, data: payload};
        case SEARCH_CATEGORIES_ERROR:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}

export default CategoryReducer;

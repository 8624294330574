import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import {Route, Switch, useLocation} from "react-router-dom";
import {Routes} from "../../models/Route";
import AddressView from "./AddressView";
import PaymentView from "./PaymentView";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import OrderReviewView from "./OrderReviewView";
import {Currencies} from "../../models/Currency";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";

const CheckOutView = () => {

    const location = useLocation();
    const analytics = useAnalytics();
    const submitted = useRef(false);
    const cart = useSelector(state => state.orders.cart);
    const [state, setState] = useState({
        ...cart,
        billing: {},
        shipping: {},
        transaction: {currency: Currencies.EUR.code},
        billingAsShipping: false
    });
    const active = () => {
        const locations = [Routes.CHECKOUT_BILLING, Routes.CHECKOUT_SHIPPING, Routes.CHECKOUT_PAYMENT, Routes.CHECKOUT_REVIEW];
        let idx = locations.indexOf(location.pathname);
        if (idx === -1 && location.pathname === Routes.CHECKOUT) idx = 0;
        return idx;
    }

    useEffect(() => {
        if (!cart || submitted.current) return;
        submitted.current = true;
        analytics.event(Event.create(Event.BEGIN_CHECKOUT, {
            currency: Currencies.EUR.code,
            value: cart.amount,
            items: cart.products.map((p,i) => analytics.product(p.product, i, p.quantity))
        }))
    }, [cart])

    return <div className="container mt-5 py-4 px-xl-5">
        <Breadcrumbs data={["Billing Address", "Shipping Address", "Payment Information", "Confirmation"]}
                     links={[Routes.CHECKOUT_BILLING, Routes.CHECKOUT_SHIPPING, Routes.CHECKOUT_PAYMENT]} active={active()}/>
        <div className="row">
            <div className="col-lg-12">
                <Switch>
                    <Route path={Routes.CHECKOUT} exact>
                        <AddressView state={state} setState={setState} property="billing" key="1"
                                     path={Routes.CHECKOUT_SHIPPING}/>
                    </Route>
                    <Route path={Routes.CHECKOUT_BILLING} exact>
                        <AddressView state={state} setState={setState} property="billing" key="1"
                                     path={Routes.CHECKOUT_SHIPPING}/>
                    </Route>
                    <Route path={Routes.CHECKOUT_SHIPPING} exact>
                        <AddressView state={state} setState={setState} property="shipping" key="2"
                                     showGlueChoice path={Routes.CHECKOUT_PAYMENT}/>
                    </Route>
                    <Route path={Routes.CHECKOUT_PAYMENT} exact>
                        <PaymentView state={state} setState={setState} path={Routes.CHECKOUT_REVIEW}/>
                    </Route>
                    <Route path={Routes.CHECKOUT_REVIEW} exact>
                        <OrderReviewView state={state}/>
                    </Route>
                </Switch>
            </div>
        </div>
    </div>
}

export default CheckOutView;

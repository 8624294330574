class ImageTool {
    static preload = (src) => {
        console.info("image.preload", src);
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = function() {
                resolve(img)
            }
            img.onerror = img.onabort = (e) => {
                console.info("image.preload.error", e)
                reject(src)
            }
            img.src = src
        })
    }
}

export default ImageTool;

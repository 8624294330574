import {FormProvider, useForm} from "react-hook-form";
import {Input} from "../form/Input";
import {
    cardCvvField,
    cardExpirationMonthField,
    cardExpirationYearField,
    cardHolderField,
    cardNumberField
} from "./PaymentFields";
import {useHistory} from "react-router-dom";
import StateTool from "../../tools/StateTool";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icons from "../../models/Icons";
import {useDispatch, useSelector} from "react-redux";
import {checkout, findTransactions, getCart, getPaymentToken} from "../../actions/Orders";
import {ColorRing} from "react-loader-spinner";
import PaymentTool from "../../tools/PaymentTool";
import Message from "../form/Message";
import StringTool from "../../tools/StringTool";
import OrderSummary from "./OrderSummary";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import {Currencies} from "../../models/Currency";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";

const PaymentView = ({state, setState, path}) => {

    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const history = useHistory();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const tool = new StateTool(state, setState);
    const [data, setData] = useState({...state.transaction, loading: false});
    const [error, setError] = useState();
    const cart = useSelector(state => state.orders.cart);
    const token = useSelector(state => state.orders.token);
    const payment = new PaymentTool(token);

    const onSubmit = form.handleSubmit(submit => {
        console.info("submit.payment", submit);
        setError(null);
        const submission = {...state.transaction, ...submit, amount: cart?.amount};
        tool.set("transaction", submission);
        if (!isValid()) {
            showErrors();
            return;
        }
        sendPaymentEvent();
        setData({...data, loading: true});
        payment.pay({...state, transaction: submission}).then(result => {
            console.info("payment.success", result);
            const transaction = {
                ...submission, nonce: result.nonce, cardType: result.cardType, userId: cart.userId,
                cardNumber: submission.cardNumber.substr(-4)
            }
            const payload = {
                ...cart, billing: state.billing, billingAsShipping: state.billingAsShipping,
                shipping: state.billingAsShipping ? null : state.shipping, transaction
            };
            setData({...data, loading: false});
            dispatch(checkout(payload)).then(result => {
                tool.set("order", payload);
                sendPurchaseEvent(result?.data);
                dispatch(getCart());
                dispatch(findTransactions({
                    page: 1,
                    size: 28,
                    filter: [],
                    sort: [{property: 'timeCreated', direction: -1}]
                }));
                history.push(path);
            }).catch(error => {
                setData({...data, loading: false, error: "Checkout issue has occurred : " + error.message});
            })
        }).catch(error => {
            console.error("braintree.pay.error", error)
            setData({...data, loading: false, error: error.message + " " + error.details?.invalidFieldKeys?.join()});
        });
        console.info("form.submit", submit, data, state);
    })

    const onChange = (property) => (event) => {
        setData({...data, [property]: event.target.value});
    }

    const isValid = () => {
        return StringTool.isNotBlank(state.billing.firstName) &&
            (StringTool.isNotBlank(state.shipping.firstName) || state.billingAsShipping) || cart.amount > 0;
    }

    const showErrors = () => {
        if (StringTool.isBlank(state.billing.firstName))
            setError("Please provide billing address information");
        else if (!state.billingAsShipping && StringTool.isBlank(state.shipping.firstName))
            setError("Please provide shipping address information");
    }

    const sendPaymentEvent = () => {
        analytics.reset();
        analytics.event(Event.create(Event.ADD_PAYMENT_INFO, {
            currency: Currencies.EUR.code,
            value: cart.amount,
            payment_type: "Credit Card",
            items: cart.products.map((p,i) => analytics.product(p.product, i, p.quantity))
        }))
    }

    const sendPurchaseEvent = (order) => {
        analytics.reset();
        analytics.event(Event.create(Event.PURCHASE, {
            currency: Currencies.EUR.code,
            value: order.price,
            tax: order.tax,
            shipping: cart.ship,
            transaction_id: order.transaction?.id,
            items: cart.products.map((p,i) => analytics.product(p.product, i, p.quantity))
        }))
    }

    useEffect(() => {
        if (!token) dispatch(getPaymentToken()).then(_ => setData({...data, loading: false}));
    }, [token])

    useEffect(() => {
        showErrors();
    }, [state])

    return <FormProvider {...form}>
        <ScrollToTopOnMount/>
        <form noValidate onSubmit={e => e.preventDefault()} className="row">
            <div className="col-lg-8">
                <div className="row">
                    <div className="form-outline col-md-6">
                        <div className="mb-4">
                            <Input {...cardHolderField} value={data?.cardHolder ?? ''} icon={Icons.CARD_HOLDER}
                                   onChange={onChange("cardHolder")}/>
                        </div>
                        <div className="mb-4">
                            <Input {...cardNumberField} value={data?.cardNumber ?? ''} icon={Icons.CARD_NUMBER}
                                   onChange={onChange("cardNumber")}/>
                        </div>
                        <div className="mb-4 payment_methods">
                                <img alt="American Express" src={process.env.PUBLIC_URL + "/images/amex.png"}/>
                                <img alt="Discover" src={process.env.PUBLIC_URL + "/images/discover.png"}/>
                                <img alt="JCB" src={process.env.PUBLIC_URL + "/images/jcb.png"}/>
                                <img alt="MasterCard" src={process.env.PUBLIC_URL + "/images/master-card.png"}/>
                                <img alt="Visa" src={process.env.PUBLIC_URL + "/images/visa.png"}/>
                        </div>
                    </div>
                    <div className="form-outline col-md-6">
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <Input {...cardExpirationMonthField} value={data?.cardExpirationMonth ?? ''} icon={Icons.EXPIRY_MONTH}
                                   onChange={onChange("cardExpirationMonth")}/>
                        </div>
                        <div className="col-md-6 mb-4">
                            <Input {...cardExpirationYearField} value={data?.cardExpirationYear ?? ''} icon={Icons.EXPIRY_YEAR}
                                   onChange={onChange("cardExpirationYear")}/>
                        </div>
                    </div>
                    <div className="mb-4">
                        <Input {...cardCvvField} value={data?.cardCvv ?? ''} icon={Icons.CARD_CVV}
                               onChange={onChange("cardCvv")}/>
                    </div>
                    <div className="mb-4 position-relative">
                        <button className="btn btn-primary btn-lg w-100" onClick={onSubmit} disabled={data.loading || !token || !isValid() || error}>
                            <FontAwesomeIcon icon={Icons.CHECKOUT}/>
                            &nbsp; Checkout
                        </button>
                        <div className="mb-4 ta-right position-absolute" style={{top: 0, left:0, marginLeft: -100}}>
                            <ColorRing width={100} height={60} visible={data.loading} colors={["#000", "#666", "#999"]}
                                       wrapperStyle={{float: 'right', marginTop: -5}}/>
                        </div>
                    </div>
                </div>
                </div>
                {(error || data.error) && <Message level="danger" text={error || data.error} title="Checkout Error" />}
            </div>
            {cart && <OrderSummary cart={cart}/>}
        </form>
    </FormProvider>
}

export default PaymentView;

export default class StringTool {

    static isBlank(input) {
        return !input || "" === input || input.length === 0
    }

    static isNotBlank(input) {
        return !this.isBlank(input);
    }

}

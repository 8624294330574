export default class ErrorTool {

    error;

    constructor(error) {
        this.error = error;
    }

    getMessage() {
        return this.error.errors ?
            this.error.errors?.map(e => {
                const match = e.match(/(Invalid\s*property\s*[\[\]\w]+\s*value\s*found)/)
                return match[0];
            }).join(", ") : this.error.message
    }

}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Message = ({level, title, text, className}) => {

    const textStyle = {overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'};

    return <div className={`alert alert-${level} ${className ?? ''}`}>
        {title && <span>
            <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> &nbsp;&nbsp;
            <strong>{title}</strong>
        </span>}
        <span style={textStyle}>{text}</span>
    </div>
}

export default Message;

import {
    CHECKOUT,
    CHECKOUT_ERROR,
    CHECKOUT_SUCCESS,
    CREATE_CART_ITEM,
    CREATE_CART_ITEM_ERROR,
    CREATE_CART_ITEM_SUCCESS,
    FIND_ORDER,
    FIND_ORDER_ERROR,
    FIND_ORDER_SUCCESS,
    FIND_TRANSACTIONS,
    FIND_TRANSACTIONS_ERROR,
    FIND_TRANSACTIONS_SUCCESS,
    GET_CART,
    GET_CART_COUNT,
    GET_CART_COUNT_ERROR,
    GET_CART_COUNT_SUCCESS,
    GET_CART_ERROR,
    GET_CART_RESET,
    GET_CART_SUCCESS,
    GET_PAYMENT_TOKEN,
    GET_PAYMENT_TOKEN_ERROR,
    GET_PAYMENT_TOKEN_SUCCESS,
    REMOVE_CART_ITEM,
    REMOVE_CART_ITEM_ERROR,
    REMOVE_CART_ITEM_SUCCESS,
    UPDATE_CART_ITEM,
    UPDATE_CART_ITEM_ERROR,
    UPDATE_CART_ITEM_SUCCESS
} from "./Types";
import OrderService from "../services/OrderService";

export const getCart = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CART,
        });
        const res = await OrderService.getCart();
        if (res?.status === 200)
            dispatch({
                type: GET_CART_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: GET_CART_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res);
    } catch (error) {
        console.error(error);
        dispatch({
            type: GET_CART_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const getCartCount = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_CART_COUNT,
        });
        const res = await OrderService.getCartCount();
        if (res?.status === 200)
            dispatch({
                type: GET_CART_COUNT_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: GET_CART_COUNT_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res);
    } catch (error) {
        console.error(error);
        dispatch({
            type: GET_CART_COUNT_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const resetCart = () => async (dispatch) => {
    dispatch({
        type: GET_CART_RESET,
        payload: undefined
    });
}

export const updateCartItem = (item) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_CART_ITEM
        });
        const res = await OrderService.updateCartItem(item);
        if (res?.status === 200)
            dispatch({
                type: UPDATE_CART_ITEM_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: UPDATE_CART_ITEM_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res);
    } catch (error) {
        console.error(error);
        dispatch({
            type: UPDATE_CART_ITEM_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const createCartItem = (item) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_CART_ITEM
        });
        const res = await OrderService.createCartItem(item);
        if (res?.status === 200)
            dispatch({
                type: CREATE_CART_ITEM_SUCCESS,
                payload: res?.data,
            });
        else
            dispatch({
                type: CREATE_CART_ITEM_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res);
    } catch (error) {
        console.error(error);
        dispatch({
            type: CREATE_CART_ITEM_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const removeCartItem = (item) => async (dispatch) => {
    try {
        dispatch({
            type: REMOVE_CART_ITEM
        });
        const res = await OrderService.removeCartItem(item);
        if (res?.status === 200)
            dispatch({
                type: REMOVE_CART_ITEM_SUCCESS,
                payload: item,
            });
        else
            dispatch({
                type: REMOVE_CART_ITEM_ERROR,
                payload: res?.data,
            });
        return Promise.resolve(res);
    } catch (error) {
        console.error(error);
        dispatch({
            type: REMOVE_CART_ITEM_ERROR,
            payload: error,
        });
        return Promise.reject(error);
    }
}

export const getPaymentToken = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_PAYMENT_TOKEN
        });
        const res = await OrderService.getPaymentToken();
        if (res?.status === 200)
            dispatch({
                type: GET_PAYMENT_TOKEN_SUCCESS,
                payload: res?.data
            });
        else
            dispatch({
                type: GET_PAYMENT_TOKEN_ERROR,
                payload: res?.data
            });
        return Promise.resolve(res);
    } catch (error) {
        dispatch({
            type: GET_PAYMENT_TOKEN_ERROR,
            payload: error
        });
        return Promise.reject(error);
    }
}

export const checkout = (order) => async (dispatch) => {
    try {
        dispatch({
            type: CHECKOUT
        });
        const res = await OrderService.checkout(order);
        if (res?.status === 200)
            dispatch({
                type: CHECKOUT_SUCCESS,
                payload: res?.data
            });
        else
            dispatch({
                type: CHECKOUT_ERROR,
                payload: res?.data
            });
        return Promise.resolve(res);
    } catch (error) {
        dispatch({
            type: CHECKOUT_ERROR,
            payload: error
        });
        return Promise.reject(error);
    }
}

export const findOrder = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FIND_ORDER,
            payload: id
        });
        const res = await OrderService.findById(id);
        if (res?.status === 200)
            dispatch({
                type: FIND_ORDER_SUCCESS,
                payload: res?.data
            });
        else
            dispatch({
                type: FIND_ORDER_ERROR,
                payload: res?.data
            });
        return Promise.resolve(res);
    } catch (error) {
        dispatch({
            type: FIND_ORDER_ERROR,
            payload: error
        });
        return Promise.reject(error);
    }
}

export const findTransactions = (query) => async (dispatch) => {
    try {
        dispatch({
            type: FIND_TRANSACTIONS,
            payload: query
        });
        const res = await OrderService.searchTransactions(query);
        if (res?.status === 200)
            dispatch({
                type: FIND_TRANSACTIONS_SUCCESS,
                payload: res?.data
            });
        else
            dispatch({
                type: FIND_TRANSACTIONS_ERROR,
                payload: res?.data
            });
        return Promise.resolve(res);
    } catch (error) {
        dispatch({
            type: FIND_TRANSACTIONS_ERROR,
            payload: error
        });
        return Promise.reject(error);
    }
}

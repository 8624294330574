export default class StateTool {

    state;
    setState;

    constructor(state, setState) {
        this.state = state;
        this.setState = setState;
    }

    set(property, value) {
        return StateTool.setState(this.state, this.setState, property, value);
    }

    apply(value) {
        return this.setState({
            ...this.state,
            ...value
        });
    }

    static setState(state, setState, property, value) {
        // console.info("state.set", state, property, value)
        return setState({
            ...state,
            [property]: value
        })
    }

}

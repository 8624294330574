import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input} from "../form/Input";
import Icons from "../../models/Icons";
import Message from "../form/Message";
import {FormProvider, useForm} from "react-hook-form";
import {useRef, useState} from "react";
import {emailField, messageField, nameField, subjectField} from "./ContactFields";
import TextArea from "../form/TextArea";
import StateTool from "../../tools/StateTool";
import {useDispatch} from "react-redux";
import {sendMessage} from "../../actions/Contact";
import {Event} from "../../models/Analytics";
import useAnalytics from "../../hooks/Analytics";
import ReCAPTCHA from "react-google-recaptcha"
import ErrorTool from "../../tools/ErrorTool";

const ContactForm = () => {

    const [state, setState] = useState({
        error: undefined,
        submitted: false
    });
    const tool = new StateTool(state, setState);
    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const recaptchaRef = useRef();
    const collapseRef = useRef();
    const buttonRef = useRef();
    const collapseForm = () => {
        buttonRef.current?.click();
    }
    const onSubmit = form.handleSubmit(data => {
        tool.apply({
            error: undefined,
            submitted: true,
            loading: true
        })
        recaptchaRef.current?.reset();
        dispatch(sendMessage(data?.email, data?.name, data?.subject, data?.message)).then(_ => {
            tool.set("loading", false);
            collapseForm();
            form.reset();
            analytics.event(Event.create(Event.SEND_MESSAGE, {...data, message: "N/A"}));
        }).catch(error => {
            tool.apply({
                loading: false,
                error: error?.response?.data
            });
            collapseForm();
            analytics.event(Event.create(Event.EXCEPTION,
                {
                    ...(error?.response?.data?.message ? error?.response?.data : {}),
                    status: error?.code,
                    code: error?.request?.status,
                    method: error?.config?.method?.toUpperCase(),
                    url: error?.request?.responseURL ?? error?.response?.data?.url,
                    description: error?.response?.data?.message ?? error?.message,
                    time: error?.response?.data?.time ?? new Date().getTime(),
                    fatal: false
                }
            ))
        });
    });

    const onExpand = (e) => {
        form.clearErrors();
    }

    return <div id="accordionForm" className="accordion">
        <div className="card p-2 pb-1">
            <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" onClick={onExpand}
                        data-bs-target="#collapseForm" aria-expanded="false" aria-controls="collapseForm" ref={buttonRef}>
                    <p className="lead fw-normal mb-0 me-3">Contact Us</p>
                </button>
            </h2>
        </div>
        <div id="collapseForm" className="accordion-collapse collapse" data-bs-parent="#accordionForm" ref={collapseRef}>
            <div className="accordion-body p-0">
                <FormProvider {...form}>
                    <div className="bg-white p-3">
                        <form noValidate onSubmit={e => e.preventDefault()}>

                            <div className="form-outline mb-3">
                                <Input {...emailField}/>
                            </div>

                            <div className="form-outline mb-3">
                                <Input {...nameField} />
                            </div>

                            <div className="form-outline mb-3">
                                <Input {...subjectField} />
                            </div>

                            <div className="form-outline mb-3">
                                <TextArea {...messageField} style={{maxHeight: 200, minHeight: 100}} />
                            </div>

                            <div className="row">
                                <div className="col col-md-6">
                                    <button type="button" className="btn btn-primary btn-lg float-start" onClick={onSubmit}
                                            style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}} disabled={state.loading}>
                                        <FontAwesomeIcon icon={Icons.SEND_MESSAGE}/> &nbsp; Send
                                    </button>
                                </div>
                            </div>
                            {state.submitted && <div className="row col col-md-12 m-0 mt-3 mb-0">
                                {state.error && <Message level="warning" title="Contact Error" text={new ErrorTool(state.error).getMessage()} className="mb-1"/>}
                                {!state.error && <Message level="success" title="Message Sent" text="Thank you, your message was sent!" className="mb-1"/>}
                            </div>}
                            <div className="row">
                                <div className="col col-md-3 mt-0">
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY} badge="bottomleft"
                                        grecaptcha={recaptchaRef} size="invisible" type="image" isolated={true}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </FormProvider>
            </div>
        </div>
    </div>

}

export default ContactForm;

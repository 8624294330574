import {
    CHECKOUT,
    CHECKOUT_ERROR,
    CHECKOUT_SUCCESS,
    CREATE_CART_ITEM,
    CREATE_CART_ITEM_ERROR,
    CREATE_CART_ITEM_SUCCESS,
    FIND_ORDER,
    FIND_ORDER_ERROR,
    FIND_ORDER_SUCCESS,
    FIND_TRANSACTIONS,
    FIND_TRANSACTIONS_ERROR,
    FIND_TRANSACTIONS_SUCCESS,
    GET_CART,
    GET_CART_COUNT,
    GET_CART_COUNT_ERROR,
    GET_CART_COUNT_SUCCESS,
    GET_CART_ERROR,
    GET_CART_RESET,
    GET_CART_SUCCESS,
    GET_PAYMENT_TOKEN,
    GET_PAYMENT_TOKEN_ERROR,
    GET_PAYMENT_TOKEN_SUCCESS,
    REMOVE_CART_ITEM,
    REMOVE_CART_ITEM_ERROR,
    REMOVE_CART_ITEM_SUCCESS,
    SET_ORDER,
    UPDATE_CART_ITEM,
    UPDATE_CART_ITEM_ERROR,
    UPDATE_CART_ITEM_SUCCESS
} from "../actions/Types";
import State from "../models/State";
import OrderTool from "../tools/OrderTool";

const initialState = new State();

const OrderReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CART:
            return {...state, loading: true};
        case GET_CART_SUCCESS:
            if (!payload.products) payload.products = [];
            inflate(payload);
            return {...state, loading: false, cart: payload, count: payload.count, error: null};
        case GET_CART_ERROR:
            return {...state, loading: false, error: payload, cart: null};
        case GET_CART_COUNT:
            return {...state, loading: true, count: 0};
        case GET_CART_COUNT_SUCCESS:
            return {...state, loading: false, count: payload.count || 0, error: null};
        case GET_CART_COUNT_ERROR:
            return {...state, loading: false, error: payload};
        case GET_CART_RESET:
            return {...state, loading: false, error: null, cart: null, count: 0};
        case CREATE_CART_ITEM:
            return {...state, loading: true}
        case CREATE_CART_ITEM_SUCCESS:
            const item = state.cart?.products.find(p => p.product.id === payload.product.id);
            if (!item) state.cart?.products.push(payload);
            else item.quantity += payload.quantity;
            inflate(state.cart);
            return {...state, loading: false, count: state.cart.count}
        case CREATE_CART_ITEM_ERROR:
            return {...state, loading: false, error: payload}
        case UPDATE_CART_ITEM:
            return {...state, loading: true}
        case UPDATE_CART_ITEM_SUCCESS:
            const idx = state.cart?.products.findIndex(p => p.id === payload.id);
            state.cart.products.splice(idx, 1, payload);
            inflate(state.cart);
            return {...state, loading: false, count: state.cart.count}
        case UPDATE_CART_ITEM_ERROR:
            return {...state, loading: false, error: payload};
        case REMOVE_CART_ITEM:
            return {...state, loading: true};
        case REMOVE_CART_ITEM_SUCCESS:
            const idxRemove = state.cart?.products.findIndex(p => p.id === payload.id);
            if (idxRemove > -1) {
                state.cart?.products.splice(idxRemove, 1);
                inflate(state.cart);
            }
            return {...state, loading: false, count: state.cart.count};
        case REMOVE_CART_ITEM_ERROR:
            return {...state, loading: false, error: payload};
        case GET_PAYMENT_TOKEN:
            return {...state, loading: true};
        case GET_PAYMENT_TOKEN_SUCCESS:
            return {...state, loading: false, token: payload.token, error: null};
        case GET_PAYMENT_TOKEN_ERROR:
            return {...state, loading: false, error: payload, token: null};
        case CHECKOUT:
            return {...state, loading: true};
        case CHECKOUT_SUCCESS:
            return {...state, loading: false, cart: payload};
        case CHECKOUT_ERROR:
            return {...state, loading: false, error: payload};
        case FIND_ORDER:
            return {...state, loading: true};
        case FIND_ORDER_SUCCESS:
            return {...state, loading: false, order: payload, error: null};
        case FIND_ORDER_ERROR:
            return {...state, loading: false, error: payload};
        case SET_ORDER:
            return {...state, order: payload};
        case FIND_TRANSACTIONS:
            return {...state, loading: true};
        case FIND_TRANSACTIONS_SUCCESS:
            return {...state, loading: false, transactions: payload, error: null};
        case FIND_TRANSACTIONS_ERROR:
            return {...state, loading: false, error: payload};
        default:
            return state;
    }
}

export default OrderReducer;

const inflate = (cart) => {
    const tool = new OrderTool(cart);
    cart.price = tool.productsAmount();
    cart.tax = tool.taxAmount();
    cart.ship = tool.shippingAmount();
    cart.discount = tool.discountAmount();
    cart.count = tool.count();
    cart.amount = tool.totalAmount();
}

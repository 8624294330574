import {useParams} from "react-router-dom";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {findProduct, findRelatedProducts} from "../../actions/Products";
import ProductDetail from "./ProductDetail";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import RelatedProducts from "./RelatedProducts";
import {SET_PRODUCT} from "../../actions/Types";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import {Currencies} from "../../models/Currency";

const ProductView = () => {

    const params = useParams()
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const [state, setState] = useState({quantity: 1});
    const product = useSelector(state => state.products.product);
    const products = useSelector(state => state.products.related);
    const loading = useSelector(state => state.products.loading);

    useEffect(() => {
        dispatch(findProduct(params.id));
        return () => {
            dispatch({
                type: SET_PRODUCT,
                payload: undefined
            })
        }
    }, [dispatch, params.id])

    useEffect(() => {
        if (!product) return;
        dispatch(findRelatedProducts(product));
        analytics.reset();
        analytics.event(Event.create(Event.VIEW_ITEM, {
            currency: Currencies.EUR.code,
            value: product.price,
            items: [analytics.product(product)]
        }))
    }, [product])

    return (
        <div className="container mt-5 py-4 px-xl-5">
            <ScrollToTopOnMount/>
            {!loading && product && <>
                <Breadcrumbs data={["Product Catalog", product?.category?.name, product?.name]} links={["/products"]}/>
                <ProductDetail product={product} state={state} setState={setState}/>
            </>}
            {!loading && product && products && <RelatedProducts products={products} />}
        </div>
    );
}

export default ProductView;

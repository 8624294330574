import {useEffect, useRef, useState} from "react";

const AnimateSection = ({children}) => {

    const ref = useRef();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setTimeout(() => {
                    setVisible(true);
                    observer.unobserve(ref.current);
                }, 0)
            }
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return (<section ref={ref} className={'animate ' + (visible ? ' visible' : '')}>{children}</section>);
};

export default AnimateSection;

import Template from "./template/Template";
import ProductView from "./components/products/ProductView";
import {Switch, Route} from "react-router-dom";
import LandingView from "./components/landing/LandingView";
import ProductListView from "./components/products/ProductListView";
import {interceptor} from "./http/Interceptor";
import UserLoginView from "./components/auth/UserLoginView";
import UserRegistrationView from "./components/auth/UserRegistrationView";
import ShoppingCartView from "./components/orders/ShoppingCartView";
import {useDispatch} from "react-redux";
import {init} from "./actions/Auth";
import {Routes} from "./models/Route";
import CheckOutView from "./components/orders/CheckOutView";
import OrderView from "./components/orders/OrderView";
import UserPasswordView from "./components/auth/UserPasswordView";
import useAnalytics from "./hooks/Analytics";
import UserProfileView from "./components/auth/UserProfileView";
import ContactView from "./components/contact/ContactView";
import ServiceView from "./components/services/ServiceView";
import CompanyView from "./components/company/CompanyView";
import ImageLoader from "./components/loader/ImageLoader";
import ScriptLoader from "./components/loader/ScriptLoader";

const App = () => {
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    dispatch(init()).then(_ => {
        analytics.init();
        analytics.view();
    })
    interceptor();
    return (
        <Template>
            <ImageLoader/>
            <ScriptLoader/>
            <Switch>
                <Route path={Routes.ROOT} exact>
                    <LandingView />
                </Route>
                <Route path={Routes.PRODUCTS} exact>
                    <ProductListView />
                </Route>
                <Route path={Routes.SERVICES} exact>
                    <ServiceView />
                </Route>
                <Route path={Routes.COMPANY} exact>
                    <CompanyView />
                </Route>
                <Route path={Routes.PRODUCT}>
                    <ProductView />
                </Route>
                <Route path={Routes.CONTACT}>
                    <ContactView />
                </Route>
                <Route path={Routes.CART} exact>
                    <ShoppingCartView />
                </Route>
                <Route path={Routes.CHECKOUT}>
                    <CheckOutView />
                </Route>
                <Route path={Routes.ORDER}>
                    <OrderView />
                </Route>
                <Route path={Routes.USER_PROFILE} exact>
                    <UserProfileView />
                </Route>
                <Route path={Routes.USER_SIGN_IN} exact>
                    <UserLoginView />
                </Route>
                <Route path={Routes.USER_SIGN_UP} exact>
                    <UserRegistrationView />
                </Route>
                <Route path={Routes.USER_PASS_RESET} exact>
                    <UserPasswordView />
                </Route>
            </Switch>
        </Template>
    );
}

export default App;

import Patterns from "../../models/Patterns";

export const usernameField = {
    name: 'username',
    label: 'username',
    type: 'text',
    id: 'username',
    placeholder: 'E-Mail / Username',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 32,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.USERNAME_OR_EMAIL,
            message: "bad format"
        }
    },
    className: "form-control form-control-lg"
};

export const passwordField = {
    name: 'password',
    label: 'password',
    type: 'password',
    id: 'password',
    placeholder: 'Password',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 8,
            message: '8 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.PASSWORD,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};

export const rememberField = {
    name: 'remember',
    label: 'remember',
    type: 'checkbox',
    id: 'remember',
    placeholder: 'Remember me',
    validation: {},
    className: "form-check-input me-2"
}

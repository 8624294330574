export default class Currency {
    code;
    name;
    symbol;
    constructor(code, symbol, name) {
        this.code = code;
        this.name = name;
        this.symbol = symbol;
    }
}

export class Currencies {
    static EUR = new Currency("EUR", "€", "Euro");
}

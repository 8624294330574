import http from "../http/Axios";
import AuthService from "./AuthService";

class OrderService {

    getCart() {
       return http.get("/data/http/orders/cart")
    }

    getCartCount() {
        return http.get("/data/http/orders/cart/count")
    }

    updateCartItem(item) {
        return http.put("/data/http/orders/cart/items", item);
    }

    createCartItem(item) {
        return http.post("/data/http/orders/cart/items", item);
    }

    removeCartItem(item) {
        return http.delete("/data/http/orders/cart/items/" + item.id, {
            headers: {
                Authorization: "Bearer " + AuthService.authentication.token
            },
            data: item
        });
    }

    getPaymentToken() {
        return http.get("/data/http/orders/payment/token");
    }

    checkout(order) {
        return http.post("/data/http/orders", order);
    }

    findById(id) {
        return http.get("/data/http/orders/" + id);
    }

    searchTransactions(query) {
        return http.post("/data/http/transactions", query);
    }

}

const service = new OrderService();
export default service;

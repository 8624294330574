import {HIDE_MODAL, SHOW_MODAL} from "../actions/Types";

const initialState = {};

const ModalReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_MODAL:
            console.info("Modal.reduce", payload, state)
            return {...state, data: payload};
        case HIDE_MODAL:
            return {...state, data: null};
        default:
            return state;
    }
}

export default ModalReducer;

import Image from "../image/Image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";
import {removeCartItem, updateCartItem} from "../../actions/Orders";
import {Link} from "react-router-dom";
import {faWindowClose} from "@fortawesome/free-regular-svg-icons/faWindowClose";
import PropTypes from "prop-types";
import ProductTool from "../../tools/ProductTool";
import {useState} from "react";
import Icons from "../../models/Icons";
import {showModal} from "../../actions/Modal";
import Modal from "../../models/Modal";
import StringTool from "../../tools/StringTool";

const ProductCartCard = ({product, editable, showRemoveButton, onRemove, onUpdate}) => {

    const dispatch = useDispatch();
    const tool = new ProductTool(product.product);
    const [quantity, setQuantity] = useState(product.quantity);
    let percentOff;
    let offPrice = `${product?.product?.price} €`;

    if (product?.product?.discount && product?.product?.discount > 0) {
        percentOff = (
            <div className="badge bg-dim py-2 text-white position-absolute" style={{top: "0.5rem", left: "0.5rem"}}>
                {product?.product?.discount}% OFF
            </div>
        );
        offPrice = (
            <>
                <del>{product?.product?.price} €</del>&nbsp;&nbsp;
                {product?.product?.price - tool.discountPrice()} €
            </>
        );
    }

    const updateQuantity = (event) => {
        if (StringTool.isBlank(event.target.value)) setQuantity(1);
        const qty = StringTool.isNotBlank(event.target.value) ? parseInt(event.target.value) : 1;
        if (product.quantity === qty) return;
        product.quantity = qty > 0 ? qty : 1;
        dispatch(updateCartItem(product)).then(_ => {
            onUpdate(product);
        })
    }

    const removeItem = () => {
        dispatch(removeCartItem(product)).then(_ => {
            onRemove(product);
        });
    }

    const displayModal = () => {
        const content = <>
            <div className="rounded-circle shadow-4-strong" style={{marginBottom: 10}}>
                <FontAwesomeIcon icon={Icons.CART_REMOVE_DIALOG} style={{width: 150, height: 150}}/>
            </div>
            <div className="text-primary" style={{lineHeight: '18px'}}>
                {product.quantity} item(s) of <b>{product.product.name}</b> will be removed from your Shopping Cart
            </div>
        </>;
        const controls = <>
            <button className="btn btn-dark py-2 w-100" onClick={removeItem} >
                <FontAwesomeIcon icon={Icons.CART_REMOVE_PROCEED}/>&nbsp; Remove
            </button>
            <button className="btn btn-outline-dark py-2 w-100">
                <FontAwesomeIcon icon={Icons.CLOSE} /> &nbsp; Cancel
            </button>
        </>
        dispatch(showModal(new Modal("Remove from Cart", content, controls)))
    }

    const uri = "/products/" + (product.product.productId ? product.product.productId : product.product.id);

    return (<div className="card shadow-sm mb-4" style={{paddingRight: 0}}>
            <div className="row g-0">
                <div className="col-4 col-md-2">
                    <Link to={uri} style={{height: '100%', background: "#e9e9e9", display: 'block'}}>
                        {percentOff}
                        <Image className="rounded-start bg-white cover w-100" alt="" id={product?.product?.images[0]} height={144}/>
                    </Link>
                </div>
                <div className="col-8 col-md-10">
                    <div className="card-body h-100">
                        <div className="d-flex flex-column h-100">
                            <Link to={uri} style={{textDecoration: 'none', maxWidth: '90%', overflow: 'hidden'}}>
                                <h5 className="card-title text-dark text-truncate mb-1">
                                    {product?.product?.name}
                                </h5>
                            </Link>
                            <span className="card-text text-muted mb-2 flex-shrink-0 card-title"><strong>{offPrice}</strong></span>
                            <p>
                                {product.product.dimension ? product.product.dimension : 'n/a'}<br/>
                                {product.product.material ? product.product.material : 'n/a'}
                            </p>
                            <div className="d-flex" style={{marginTop: -60}}>
                                <input min="1" max="3333" name="quantity" type="number" value={quantity}
                                       style={{maxWidth: 80}} className="form-control form-control-lg ms-auto"
                                       onChange={e => setQuantity(StringTool.isNotBlank(e.target.value) ? parseInt(e.target.value) : null)}
                                       readOnly={!!!editable} onBlur={e => updateQuantity(e)}/>
                                {showRemoveButton && <div className="text-primary" onClick={displayModal}
                                                          style={{cursor: 'pointer', position: 'absolute', right: '1rem', top: 5}}>
                                    <FontAwesomeIcon icon={faWindowClose} className="" size="lg" style={{marginTop: 13, marginLeft: 10, color: '#777'}} />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>)
}

export default ProductCartCard;

ProductCartCard.propTypes = {
    editable: PropTypes.bool.isRequired,
    showRemoveButton: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
}

ProductCartCard.defaultProps = {
    editable: true,
    showRemoveButton: true,
    onUpdate: () => {},
    onRemove: () => {}
}

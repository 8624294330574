import OrderDetail from "./OrderDetail";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {SET_ORDER} from "../../actions/Types";
import {useDispatch, useSelector} from "react-redux";
import {findOrder} from "../../actions/Orders";
import Loader from "../loader/Loader";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

const OrderView = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const order = useSelector(state => state.orders.order);
    const loading = useSelector(state => state.orders.loading);

    useEffect(() => {
        if (!order) dispatch(findOrder(params.id));
        console.info("got order", order)
        return () => {
            dispatch({
                type: SET_ORDER,
                payload: undefined
            })
        }
    }, [])

    return (loading || !order ? <Loader width={100} height={100} loading={loading}/> :
        <div className="container mt-5 py-4 px-xl-5">
            <Breadcrumbs data={["Order Information"]}/>
            <OrderDetail order={order}/>
        </div>)
}
export default OrderView;

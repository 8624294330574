import ProductCardV from "./ProductCardV";
import {useEffect} from "react";
import {Event} from "../../models/Analytics";
import useAnalytics from "../../hooks/Analytics";

const RelatedProducts = ({products}) => {

    const analytics = useAnalytics();

    useEffect(() => {
        if (!products || products.length === 0) return;
        analytics.reset();
        analytics.event(Event.create(Event.VIEW_ITEM_LIST, {
            item_list_id: "related_products",
            item_list_name: "Related Products",
            items: products.map((p, i) => analytics.product(p, i))
        }))
    }, [products])

    const onProductSelect = (product) => {
        analytics.reset();
        analytics.event(Event.create(Event.SELECT_ITEM, {
            item_list_id: "related_products",
            item_list_name: "Related Products",
            items: [analytics.product(product, products.indexOf(product))]
        }))
    }

    return (products && <div className="row">
        <div className="col-md-12 mb-4">
            <hr />
            <h5 className="text-muted my-4">Related products</h5>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3">
                {products?.map((product, i) =>
                    <ProductCardV key={i} product={product} onClick={e => onProductSelect(product)} />
                )}
            </div>
        </div>
    </div>) || null
}

export default RelatedProducts;

import Patterns from "../../models/Patterns";

export const emailField = {
    name: 'email',
    label: 'email',
    type: 'text',
    id: 'email',
    placeholder: 'E-Mail',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        maxLength: {
            value: 64,
            message: '64 max',
        },
        minLength: {
            value: 4,
            message: '4 min',
        },
        pattern: {
            value: Patterns.EMAIL,
            message: "bad format"
        }
    },
    className: "form-control form-control-lg"
};

export const passwordField = {
    name: 'password',
    label: 'password',
    type: 'password',
    id: 'password',
    placeholder: 'Password',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 8,
            message: '8 min',
        },
        maxLength: {
            value: 32,
            message: '32 max',
        },
        pattern: {
            value: Patterns.PASSWORD,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};

export const codeField = {
    name: 'code',
    label: 'code',
    type: 'number',
    id: 'code',
    placeholder: 'Authorization Code',
    validation: {
        required: {
            value: true,
            message: '*',
        },
        minLength: {
            value: 6,
            message: '6 min',
        },
        maxLength: {
            value: 6,
            message: '6 max',
        },
        pattern: {
            value: Patterns.CODE,
            message: 'bad format'
        }
    },
    className: "form-control form-control-lg"
};

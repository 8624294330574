import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icons from "../models/Icons";

const ScrollToTop = () => {

    const [showButton, setShowButton] = useState(false);

    const handleButtonVisibility = () => {
        if(window.pageYOffset > 200) setShowButton(true)
        else setShowButton(false);
    }

    const handleScrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        window.addEventListener("scroll", handleButtonVisibility);
        return () => {
            window.removeEventListener("scroll", handleButtonVisibility);
        }
    }, [])


    return (showButton && <>
        <div className="scroll-to-top-button" style={{background: 'white'}}>
            <button className="btn btn-outline-dark py-2" onClick={handleScrollToTop}>
                <FontAwesomeIcon icon={Icons.SCROLL_TO_TOP} />
            </button>
        </div>
    </>)

};

export default ScrollToTop;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormProvider, useForm} from "react-hook-form";
import {Input} from "../form/Input";
import {passwordField, rememberField, usernameField} from "./UserLoginFields";
import {useDispatch, useSelector} from "react-redux";
import {authenticate} from "../../actions/Auth";
import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import StateTool from "../../tools/StateTool";
import AuthService from "../../services/AuthService";
import Icons from "../../models/Icons";
import {Routes} from "../../models/Route";
import Message from "../form/Message";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import useImageLoader from "../../hooks/ImageLoader";
import ErrorTool from "../../tools/ErrorTool";

const UserLoginView = () => {

    const image = process.env.PUBLIC_URL + "/images/splash_01.png";
    useImageLoader([image]);
    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const history = useHistory();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const [state, setState] = useState({
        error: undefined
    });
    const tool = new StateTool(state, setState);
    const user = useSelector(state => state.auth.user);
    const loading = useSelector(state => state.auth.loading);

    const onSubmit = form.handleSubmit(data => {
        tool.set("error", undefined);
        dispatch(authenticate(data?.username, data?.password))
            .then(result => {
                analytics.event(Event.create(Event.SIGN_IN, {
                    user_id: result.id
                }))
            })
            .catch(error => {
                tool.set("error", error.response?.data);
                analytics.event(Event.create(Event.EXCEPTION,
                    {
                        ...error?.response?.data,
                        description: error?.response?.data?.message,
                        fatal: false
                    }
                ))
            });
    });

    useEffect(() => {
        if (!user || AuthService.isGuest) return;
        history.push("/");
    }, [user]);

    return <section className="" style={{marginTop: 100}}>
        <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100" style={{minHeight: 540}}>
                <div className="col-md-9 col-lg-6 col-xl-5 ta-center mb-4">
                    <img src={image} className="img-fluid" alt="User Operations"/>
                </div>
                <div className="col-md-8 col-lg-6 col-xl-3">
                    <FormProvider {...form}>
                        <form noValidate onSubmit={e => e.preventDefault()}>
                            <div
                                className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start mb-4">
                                <p className="lead fw-normal mb-0 me-3">Sign In with</p>
                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "facebook-f"]}/>
                                </button>

                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "twitter"]}/>
                                </button>

                                <button type="button" className="btn btn-primary btn-floating mx-1">
                                    <FontAwesomeIcon icon={["fab", "linkedin"]}/>
                                </button>
                            </div>

                            <div className="form-outline mb-4">
                                <Input {...usernameField}/>
                            </div>

                            <div className="form-outline mb-3">
                                <Input {...passwordField} />
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="form-check mb-0 w-60">
                                    <Input {...rememberField} value="false"/>
                                    <label className="form-check-label" htmlFor="remember">
                                        Remember me
                                    </label>
                                </div>
                                <Link to={Routes.USER_PASS_RESET}>Forgot password?</Link>
                            </div>

                            <div className="text-center text-lg-start mt-2 pt-2">
                                <div className="row">
                                    <div className="col col-md-7">
                                        <button type="button" className="btn btn-primary btn-lg float-start"
                                                onClick={onSubmit}
                                                style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}
                                                disabled={loading}>
                                            <FontAwesomeIcon icon={Icons.USER_SIGN_IN}/> &nbsp; Login
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="small fw-bold mt-2 pt-1 mb-0 ta-left">Don't have an account? &nbsp;
                                        <Link to={Routes.USER_SIGN_UP} className="link-danger">
                                            Register
                                        </Link>
                                    </p>
                                </div>
                                <div className="row col col-md-12 m-0 ta-left" style={{minHeight: 30}}>
                                    {state.error &&
                                    <Message level="warning" title="Sign In Error"
                                             text={new ErrorTool(state.error).getMessage()} className="mt-4 mb-4"/>}
                                </div>
                            </div>

                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>

    </section>
}

export default UserLoginView;

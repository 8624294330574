import {Link} from "react-router-dom";
import Image from "../image/Image";
import {Routes} from "../../models/Route";
import ProductTool from "../../tools/ProductTool";
import PropTypes from "prop-types";

const ProductCardV = ({product, onClick}) => {

    const tool = new ProductTool(product);

    let percentOff;
    let offPrice = <b>{product?.price} €</b>;

    if (product?.discount && product?.discount > 0) {
        percentOff = (
            <div className="badge bg-dim py-2 text-white position-absolute" style={{top: "0.5rem", right: "0.5rem"}}>
                {product.discount}% OFF
            </div>
        );
        offPrice = (
            <>
                <del>{product?.price} €</del>
                &nbsp;&nbsp;
                <b>{product.price - tool.discountPrice()} €</b>
            </>
        );
    }

    const uri = Routes.PRODUCT.replace(":id", product.id);

    return (
        <div className="col">
            <div className="card shadow-sm">
                <Link to={uri} style={{height: 200, background: "#e9e9e9", marginTop: 15, overflow: 'hidden'}} onClick={e => onClick(product)}>
                    {percentOff}
                    <Image className="card-img-top bg-white contain" height="270" alt="" id={product.images[0] ?? ''}
                           product={product.name} style={{marginTop: -35}}/>
                </Link>
                <div className="card-body">
                    <h5 className="card-title text-center text-dark text-truncate">
                        {product?.name}
                    </h5>
                    <p className="card-text text-center text-muted mb-0">{offPrice}</p>
                    <div className="d-grid d-block">
                        <Link to={uri} style={{marginLeft: 'auto'}} className="mt-3 w-100"
                              onClick={e => onClick(product)}>
                            <button className="btn btn-outline-dark w-100">
                                Details
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCardV;

ProductCardV.propTypes = {
    product: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired
}
ProductCardV.defaultProps = {
    onClick: () => { }
}

import {FormProvider, useForm} from "react-hook-form";
import {Input} from "../form/Input";
import {
    address2Field,
    addressField,
    cityField,
    countryField,
    emailField,
    firstNameField,
    lastNameField,
    stateField,
    zipField
} from "./AddressFields";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StateTool from "../../tools/StateTool";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import OrderSummary from "./OrderSummary";
import {useSelector} from "react-redux";
import useAnalytics from "../../hooks/Analytics";
import {Event} from "../../models/Analytics";
import {Currencies} from "../../models/Currency";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";

const AddressView = ({state, setState, property, showGlueChoice, path}) => {

    const form = useForm({mode: "onBlur", reValidateMode: "onBlur"});
    const history = useHistory();
    const analytics = useAnalytics();
    const tool = new StateTool(state, setState);
    const cart = useSelector(state => state.orders.cart);
    const [data, setData] = useState(state[property]);
    const onSubmit = form.handleSubmit(submit => {
        if (state.billingAsShipping && property === 'shipping')
            tool.set(property, {});
        else tool.set(property, {...state[property], ...submit});
        history.push(path);
        analytics.reset();
        analytics.event(Event.create(Event.ADD_SHIPPING_INFO, {
            currency: Currencies.EUR.code,
            value: cart.amount,
            shipping_tier: "Ground",
            items: cart.products.map((p,i) => analytics.product(p.product, i, p.quantity))
        }))
    })

    const onChange = (property) => (event) => {
        setData({...data, [property]: event.target.value});
    }

    return <FormProvider {...form}>
        <ScrollToTopOnMount/>
        <form noValidate onSubmit={e => e.preventDefault()} className="row">
            <div className="col-lg-8">
                <div className="row">
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...firstNameField} value={data.firstName ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("firstName")}/>
                    </div>
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...lastNameField} value={data.lastName ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("lastName")}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...addressField} value={data.address ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("address")}/>
                    </div>
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...address2Field} value={data.address2 ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("address2")}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...emailField} value={data.email ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("email")}/>
                    </div>
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...cityField} value={data.city ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("city")}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...stateField} value={data.state ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("state")}/>
                    </div>
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...zipField} value={data.zip ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("zip")}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-outline mb-4 col-md-6">
                        <Input {...countryField} value={data.country ?? ''}
                               disabled={showGlueChoice && state.billingAsShipping}
                               onChange={onChange("country")}/>
                    </div>
                    <div className="form-outline mb-4 col-md-6">
                        <button className="btn btn-primary btn-lg w-100" onClick={onSubmit}>
                            <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-right']}/>
                            &nbsp; Continue
                        </button>
                    </div>
                </div>
                <div className="row">
                    {showGlueChoice && <div style={{marginTop: -10, minHeight: 34}}>
                        <input type="checkbox" onChange={e => tool.set("billingAsShipping", !!e.target.checked)}
                               id="use-shipping-as-billing" checked={state.billingAsShipping}/>
                        &nbsp;<label htmlFor="use-shipping-as-billing">Use billing as shipping address</label>
                    </div>}
                </div>
            </div>
            {cart && <OrderSummary cart={cart}/>}
        </form>
    </FormProvider>
}

export default AddressView;

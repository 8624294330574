import BannerImage from "../landing/BanerImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icons from "../../models/Icons";
import ScrollToTopOnMount from "../../template/ScrollToTopOnMount";
import AnimateSection from "../animate/AnimateSection";

const ServiceView = () => {

    const image = process.env.PUBLIC_URL + "/images/banner_10.png";
    const text = 'Long Term Product Warranty with Extreme Weather Conditions Endurance';
    const headerStyle = {position: 'absolute', top: 40, left: 65, width: '90%', textAlign: 'left'};
    const iconStyle = {width: 80, height: 80};
    const textStyle = {marginBottom: 0};

    return <>
        <ScrollToTopOnMount/>
        <BannerImage image={image} text={text} />
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mt-1 flex-shrink-0 row-cols-xl-3 w-100">&nbsp;</div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3 mb-4 flex-shrink-0 row-cols-xl-2" style={{maxWidth: 1200, margin:'0 auto', overflow: 'hidden'}}>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="text-left px-5" style={headerStyle}>
                            <span>Engineering</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_1} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Our primary business process is the <b>design, engineering and production</b> of road traffic
                                signalization products i.e. <b>traffic signs & road equipment</b>.</span>&nbsp;
                            <span>Our products are <b>attested & certified</b>, a necessity for a high product quality.</span><br/><br/>
                            <span>The production process is <b>semi-automated</b> and backed-up by <b>contemporary machinery and technology</b>.</span>&nbsp;
                            <span>Each product can have one or more variations depending on the available materials used for construction.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Metallurgy</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_2} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Our primary industrial domain of expertise is <b>Metallurgy</b>.
                                For this purpose we utilize heavy machinery and various kinds of tools for working with & shaping metals.</span>&nbsp;
                            <span>The metals / alloys we work with are <b>Aluminium (Al)</b>, <b>Iron-Zinc (Fe-Zn)</b> & others.</span><br/><br/>
                            <span>We have expertise in engineering of machine parts used for creation of new product lines.</span>&nbsp;
                            <span>For the purpose of traffic signs & road equipment production we use metal plates with few millimeters of thickness.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Transport</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_3} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>We provide <b>transport services</b> for <b>delivering our products</b> to the desired destination.
                                For this purpose we own a small park of truck vehicles.</span><br/><br/>
                            <span>We might also utilize a third-party postal delivery service for some circumstances.</span>&nbsp;
                            <span>The transportation time (from shipping to delivery) depends mostly on the proximity of the destination, as well as transport route availability.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Installation</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_4} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>We provide <b>installation services</b> for <b>product mounting</b> on the desired locations.
                                Usually this service is used in combination with transport service, meaning we deliver and mount our traffic signalization products.</span><br/><br/>
                            <span>We can also provide <b>instructions, parts and user manuals</b> for mounting of our products by third-parties.</span>&nbsp;
                            <span>On public roads the product mount locations depend primarily on the road specification & architecture, while for private roads the mount locations are flexible and not strictly determined</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Customization</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_5} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Our products with their shapes and sizes are strictly determined by <b>international standards & road signalization rulebooks</b>.
                                We comply to the <b>European Union's standard</b> for road signs and signals.</span><br/><br/>
                            <span>Apart from the standard products, we can also produce customized road signs & signals for which purpose scalable vector graphics should be provided by the customer.</span>&nbsp;
                            <span>We can also provide <b>road sign reparation</b>, a process for renewing old & damaged road signs & signals</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
            <AnimateSection>
                <div className="col p-2 m-0 mb-3">
                    <div className="card p-3">
                        <h5 className="px-5" style={headerStyle}>
                            <span>Graphics</span>
                            <div style={{display: 'inline-block', float: 'right'}}>
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />&nbsp;
                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} style={{color: 'red'}} />
                            </div>
                        </h5>
                        <p className="text-primary" style={textStyle}>
                            <FontAwesomeIcon icon={Icons.SERVICE_INFO_6} style={iconStyle} className="service-icon" mask="square-full" inverse/>
                            <span>Graphics are an inevitable part of our products & production process, and they mostly come in the form of pictograms.
                                We apply the graphics by two main methodologies: <b>sieve stamp or self-adhesive foil</b>.</span><br/><br/>
                            <span>Apart from the standard product graphics, we can also produce & provide <b>customized commercial graphics</b>.</span>&nbsp;
                            <span>These custom graphics can be applied to flashing advertisements, retail store glass, vehicles or other public domain surfaces.</span>
                        </p>
                    </div>
                </div>
            </AnimateSection>
        </div>

    </>
}

export default ServiceView;

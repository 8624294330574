import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

const Breadcrumbs = ({data, links, active}) => {

    const [_active, _setActive] = useState(active ?? data.length - 1);

    const onClick = (i) => () => {
        if (!links || !links[i]) return;
        _setActive(i);
    }

    useEffect(() => {
        _setActive(active);
    }, [active])

    return <nav aria-label="breadcrumb" className="bg-custom-light rounded mb-4 shadow-sm mt-2">
        <ol className="breadcrumb p-3">
            {data?.map((item, i) =>
                <li className={`breadcrumb-item ${_active === i ? 'active' : ''}`} key={i} onClick={onClick(i)}>
                    <Link className={`text-decoration-none link-secondary`}
                          to={links && links[i] ? links[i] : "#"}>
                        {item}
                    </Link>
                </li>
            )}
        </ol>
    </nav>
}

export default Breadcrumbs;

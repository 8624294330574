export default class Icons {
    static USER_SIGN_IN = ["fas", "user-clock"];
    static USER_SIGN_UP = ["fas", "user-plus"];
    static USER_SIGN_OUT = ["fas", "user-minus"];
    static USER_PROFILE = ["fas", "user-edit"];
    static USER_PROFILE_MENU = ["fas", "user-alt"];
    static USER_PROFILE_ICON = ["fas", "user-circle"];
    static USER_PROFILE_EDIT = ["fas", "edit"];
    static SHOPPING_CART = ["fas", "shopping-cart"];
    static SHOPPING_CART_VIEW = ["fas", "cart-arrow-down"];
    static CHECKOUT = ["fas", "money-check"];
    static GUEST_DIALOG = ["fas", "users-slash"]
    static CART_ADD_DIALOG = ["fas", "cart-plus"]
    static CART_REMOVE_DIALOG = ["fas", "swatchbook"]
    static CART_REMOVE_PROCEED = ["fas", "window-close"]
    static CART_ADD = ["fas", "cart-plus"]
    static BUY_NOW = ["fas", "truck-loading"]
    static FIRST_NAME = ["fas", "address-book"]
    static LAST_NAME = ["fas", "user"]
    static EMAIL_ADDRESS = ["fas", "envelope"]
    static STREET_ADDRESS = ["fas", "street-view"]
    static ZIP = ["fas", "hashtag"]
    static CITY = ["fas", "city"]
    static STATE = ["fas", "chess-knight"]
    static COUNTRY = ["fas", "recycle"]
    static CLOSE = ["fas", "eye-slash"]
    static CARD_NUMBER = ["fas", "key"]
    static CARD_HOLDER = ["fas", "user"]
    static CARD_CVV = ["fas", "tag"]
    static EXPIRY_MONTH = ["fas", "calendar"]
    static EXPIRY_YEAR = ["fas", "calendar-check"]
    static SERVICE_INFO_1 = ["fab", "slack"]
    static SERVICE_INFO_2 = ["fab", "codepen"]
    static SERVICE_INFO_3 = ["fas", "globe"]
    static SERVICE_INFO_4 = ["fab", "squarespace"]
    static SERVICE_INFO_5 = ["fas", "podcast"]
    static SERVICE_INFO_6 = ["fas", "star-of-life"]
    static COMPANY_INFO_1 = ["fab", "dropbox"]
    static COMPANY_INFO_2 = ["fas", "fingerprint"]
    static COMPANY_INFO_3 = ["fas", "bullseye"]
    static COMPANY_INFO_4 = ["fas", "sun"]
    static COMPANY_INFO_5 = ["fas", "medal"]
    static COMPANY_INFO_6 = ["fas", "shekel-sign"]
    static SCROLL_TO_TOP = ["fas", "chevron-up"]
    static SEND_MESSAGE = ["fas", "envelope"]

}

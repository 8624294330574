import AuthService from "../services/AuthService";
import TagManager from "react-gtm-module";
import {Event} from "../models/Analytics";
import {useHistory} from "react-router-dom";
import ProductTool from "../tools/ProductTool";

let _initialized = false;
let _location = undefined;

const useAnalytics = () => {

    const history = useHistory();
    const context = {
        init: () => {
            if (!AuthService.authentication || _initialized) return;
            const userId = !AuthService.isGuest ? AuthService.authentication.id : undefined;
            const args = {
                gtmId: process.env.REACT_APP_ANALYTICS_ID,
                dataLayer: {
                    debug: true,
                    ...(userId ? {user_id: userId, userId: userId} : {}),
                }
            }
            // console.info("analytics.init", args);
            TagManager.initialize(args);
            _initialized = true;
            history.listen((location, action) => {
                if (location.pathname === _location) return;
                _location = location.pathname;
                context.event(Event.create(Event.PAGE_VIEW, {path: location.pathname}))
            });
        },
        event: (event) => {
            // console.info("analytics.event", event);
            TagManager.dataLayer({
                dataLayer: event
            });
        },
        view: () => {
            const event = Event.create(Event.PAGE_VIEW, {path: window.location.pathname});
            // console.info("analytics.view", event);
            TagManager.dataLayer({
                dataLayer: event
            });
        },
        product: (product, index, quantity) => {
            const tool = new ProductTool(product);
            return {
                item_id: product.sku,
                item_name: product.name,
                affiliation: "Tehnodidakta Web Store",
                discount: tool.discountPrice() || 0,
                index: index || 0,
                item_brand: "Tehnodidakta",
                item_category: product.category?.name,
                item_variant: product.material + "_" + product.dimension,
                price: product.price - tool.discountPrice(),
                quantity: quantity || 1
            }
        },
        reset: () => {
            TagManager.dataLayer({
                dataLayer: {ecommerce: null}
            });
        }
    }

    return context;
}

export default useAnalytics;
